import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

class FormItem extends Component {
    constructor(props) {
        super(props);
        this.state = { helperText:'', error:false }
    }

    getLabelText(type) {
        if (type == 'name') return 'Meno a priezvisko'
        else if (type == 'email') return 'E-mail'
        else if (type == 'text') return 'Obsah správy'
    }
    onChange(event){
        switch (this.props.type) {
            case 'name':
                if (event.target.value.length < 30 && event.target.value.length > 5) {
                    this.setState({ helperText: '', error: false });
                } else {
                    this.setState({ helperText: 'Min 5 max 30 znakov', error: true });
                }
                break;
            case 'email':
                const validEmail = new RegExp(
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                )
                
                if(validEmail.test(event.target.value)){
                    this.setState({ helperText: '', error:false})
                    console.log("valid")
                } else {
                    this.setState({helperText:'Nesprávny formát.', error:true})
                    console.log("invalid")
                }
                break;
            case 'text':
                if (event.target.value.length < 2000 && event.target.value.length > 50) {
                    this.setState({ helperText: '', error: false });
                } else {
                    this.setState({ helperText: 'Min 50 max 2000 znakov', error: true });
                }
        
            default:
                break;
        }
    }

    render() { 
        return ( 
            <TextField 
            helperText={this.state.helperText}
            onChange={this.onChange.bind(this)}
            error={this.state.error}
            label={this.getLabelText(this.props.type)}
            id={this.props.type}
            name={this.props.type}
            variant="outlined"
            required
            multiline
            rows={this.props.rows}
          />
          );
    }
}
 
export default FormItem;