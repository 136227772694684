import './App.scss';
import { Button, Container, Grid, Typography, Divider, Card, TextField, FormControl, MenuItem, Paper, Tabs } from '@material-ui/core';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com'

//icons
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import AccountCircle from '@material-ui/icons/AccountCircle'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PlaceIcon from '@material-ui/icons/Place';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FacebookIcon from '@material-ui/icons/Facebook';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


import IconItemDesc from './components/IconItemDesc'
import FormItem from './components/FormItem';
import Menu from './components/Menu';

const regions = [
  {
    value: 'PD',
    label: 'Prievidza'
  },
  {
    value: 'PB',
    label: 'Považská Bystrica'
  },
  {
    value: 'PU',
    label: 'Púchov'
  },
  {
    value: 'IL',
    label:'Ilava'
  },
  {
    value: 'TN',
    label: 'Trenčín'
  },
  {
    value: 'BN',
    label: 'Bánovce nad Bebravou'
  },
  {
    value: 'PE',
    label: 'Partizánske'
  },
  {
    value: 'NM',
    label: 'Nové Mesto nad Váhom'
  },
  {
    value: 'PN',
    label: 'Piešťany'
  },
  {
    value: 'TO',
    label: 'Topoľčany'
  },
  {
    value: 'NR',
    label: 'Nitra'
  },
  {
    value: 'ZM',
    label: 'Zlaté Moravce'
  },
  {
    value: 'ZC',
    label: 'Žarnovica'
  },
  {
    value: 'ZH',
    label: 'Žiar nad Hronom'
  },
  {
    value: 'ZV',
    label: 'Zvolen'
  },
  {
    value: 'BB',
    label: 'Bánska Bystrica'
  },
  {
    value: 'TT',
    label: 'Turčianske Teplice'
  },
  {
    value: 'MA',
    label: 'Martin'
  },
  {
    value: 'PB',
    label: 'Považská Bystrica'
  }
]

function App() {
    //AOS
    useEffect(()=>{
      AOS.init({
        duration: 300
      });
      AOS.refresh(

      )
    })

    //scroll
    const scrollDown = (event) => {
      window.scrollTo(0,document.body.scrollHeight);
    }

    //sendMail
    function sendEmail(event){
      event.preventDefault();

      emailjs.sendForm('gmail', 'contactPageTemplate', event.target, 'user_oKgcWeyDGIdbEiCJhrDq8')
      .then((result) => {
          console.log(result.text);
          var succ = document.getElementById("success");
          var form = document.getElementById("contactForm");
          succ.classList.remove("invisible");
          form.classList.add("invisible");
          
      }, (error) => {
          console.log(error.text);
      });
      event.target.reset()
    }

  const [region, setRegion] = React.useState('PD');

  const [errorText, seterrorText] = React.useState('');
  //change select
  const handleChange = (event) => {
    setRegion(event.target.value)
  }
  return (
    <Container maxWidth="xl" className="noMargin noPadding" data-aos="fade-down">
      <Grid
      container
      className="contactUsContainer"
      direction="row"
      alignContent="space-around"
      justify="center"
      >
        <Menu/>
          <Container maxWidth="lg">
            <Grid
              //className="contactBars"
              data-aos="zoom-in"
              data-aos-delay="300"
              container
              //spacing={0}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
              wrap="nowrap"
              
            >
              <IconItemDesc name="Adresa" icon={<LocationOnIcon className="contactUsIcon" fontSize="inherit"/>} description="Nevidzany 40" description2="972 27 Liešťany"/>
              <IconItemDesc name="Telefón" icon={<PhoneIcon className="contactUsIcon" fontSize="inherit"/>} description="0905232251" description2="0911795375"/>
              <IconItemDesc name="Email" icon={<InsertCommentIcon className="contactUsIcon" fontSize="inherit"/>} description="info@mjsec.sk" />
            </Grid>  
          </Container>
        <Grid
          data-aos="zoom-in"
          data-aos-delay="300"
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          wrap="nowrap"
          className="companyFocus"
        >
          <Typography variant="h4" color="initial">Zameranie spoločnosti:</Typography>
          <Typography variant="subtitle1" color="initial">Kamerové a zabezpečovacie systémy</Typography>
          <Typography variant="subtitle1" color="initial">Silno a slaboprúdové aplikácie</Typography>
          <Typography variant="subtitle1" color="initial">Elektroinštalácie</Typography>
          <Typography variant="subtitle1" color="initial">Networking</Typography>
          <Typography variant="subtitle1" color="initial">Videovrátnik, LED Osvetlenie a mnoho ďalšieho</Typography>
        </Grid>  

        <Container align="center" className="contactUsBottom">             
        <Typography variant="h4" className="relative mainColor">Prejsť na kontaktný formulár</Typography>
          <Button color="inherit" onClick={() => scrollDown()}  > 
            <ExpandMoreIcon
            fontSize="inherit" 
            data-aos="zoom-in" 
            data-aos-delay="400"
            className="scrollDownIcon"
            >
            </ExpandMoreIcon>
          </Button>
        </Container>
      </Grid>

      <Container maxWidth="md" className="pb-3 pt-3" data-aos="zoom-in" data-aos-delay="500">
        <Card>
          <Typography variant="h2" color="initial" align="center" className="pt-1">Kontaktný formulár</Typography>
          <Divider />
          <div className="success-animation invisible" id="success">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <Container align="center">
          <form className="contactForm" id="contactForm" onSubmit={sendEmail}>
            <FormControl className="formChildMargin pb-3">
              
               <Grid container spacing={0} alignItems="center">
                 <Grid item>
                   <AccountCircle fontSize="large" />
                 </Grid>
                 <Grid item>
                  <FormItem type="name"/>
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="center">
                 <Grid item>
                   <AlternateEmailIcon fontSize="large" />
                 </Grid>
                 <Grid>
                  <FormItem type="email" />
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="center">
                 <Grid item>
                   <PlaceIcon fontSize="large" />
                 </Grid>
                 <Grid item>
                  <TextField
                    id="region"
                    label="Okres"
                    variant="outlined"
                    select
                    name="region"
                    value={region}
                    onChange={handleChange}
                  >
                  {regions.map((region) => (
                  <MenuItem key={region.value} value={region.value}>
                  {region.label}
                  </MenuItem>
                  ))}
                  </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                 <Grid item>
                   <TextFieldsIcon fontSize="large" />
                 </Grid>
                
                <Grid item>
                  <FormItem type="text" rows={4} />
                </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                 <Grid item>
                   <SendIcon fontSize="large" />
                 </Grid>
                 <Grid>
                  <Button 
                  variant="outlined" 
                  color="primary"
                  type="submit"
                  >
                    Odoslať
                  </Button>
                  </Grid>
                </Grid>
                
            </FormControl>
            </form>
          </Container>
        </Card>
      </Container>
        <Grid
          data-aos="fade-up"
          data-aos-duration="1000"
          container
          spacing={0}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="nowrap"
          className="footer"
        >
          <Container maxWidth="md" align="center" className="footerContent">
            <Typography variant="h5" color="initial" className="pb-1">Mjsec s.r.o. © 2021</Typography>
            <Divider className="whiteDivider"/>
            <a href="https://www.facebook.com/MJsec-sro-101805245288964" target="_blank" className="pt-1">
            <FacebookIcon />
            </a>
            <Container maxWidth="xs" className="admin">
            <a href="https://www.mjsec.sk/admin2/login.php"><SupervisorAccountIcon /></a>
            <a href="https://www.roundcube.sk/"><MailOutlineIcon/></a>
            </Container>
          </Container>
        </Grid>
    </Container>
  );
}

export default App;
