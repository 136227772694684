import { Container,Typography, Box } from '@material-ui/core';

import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import AOS from 'aos'
import reactDom from 'react-dom';

import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import PublicIcon from '@material-ui/icons/Public';
import InfoIcon from '@material-ui/icons/Info';


function Menu(props){
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return(
        <Container
        container
        maxWidth="xl"
        className="header"
        >
        <Typography variant="h5" className="header-title" color="initial">
            <SecurityIcon className="contactUsIcon"/>MJ SECURITY
        </Typography>
        <Box className="header-title header-menu">
            <Box mx={2}>
                <Typography className="d-flex"><FilterCenterFocusIcon/>Pracovné zameranie</Typography>
            </Box>
            <Box mx={2}>
                <Typography className="d-flex"><PublicIcon/>Oblasť práce</Typography>
            </Box>
            <Box mx={2}>
                <Typography className="d-flex"><InfoIcon/>O Nás</Typography>
            </Box>
        </Box>
        
        </Container>
        
    )
}

export default Menu;