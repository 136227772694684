import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider';

import AOS from 'aos'

/* Component within icon + description */


function IconItemDesc(props){
    return(
        <Container className="bb box200x200" maxWidth="lg" align="center">
            <Container align="center">
                {props.icon}
                <Typography variant="h4" color="initial" align="center">{props.name}</Typography> 
                <Typography variant="subtitle1" color="initial" >{props.description}</Typography>
                <Typography variant="subtitle1" color="initial" >{props.description2}</Typography>
                <Divider className="defaultMargin"></Divider>
            </Container>
        </Container>
        
    )
}

export default IconItemDesc;